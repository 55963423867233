<template>
  <Transition name="slide-fade">
    <div
      class="fixed z-50 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      v-if="isModal"
    >
      <div
        class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <Transition name="slide-fade">
          <div
            class="relative bg-transparent inline-block align-bottom shadow-xl transform transition-all sm:align-middle content-frame-video"
            v-if="isModal"
          >
            <div class="flex justify-end content-button-ModalVideo">
              <button
                type="Button"
                class="btn-circle-ModalVideo absolute"
                @click.prevent="() => $emit('closeModal')"
              >
                <span class="icon-x text-agrogo-gray-1"></span>
              </button>
            </div>
            <div>
              <iframe
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; 
                gyroscope; picture-in-picture"
                allowfullscreen
                class="frame-video rounded-2xl"
                :src="urlVideo"
              ></iframe>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    urlVideo: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
