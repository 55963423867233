<template>
  <div
    class="flex relative overflow-hidden rounded-t-2xl md:rounded-t-30 justify-center"
  >
    <div class="flex w-full h-auto relative" v-if="record2">
      <img
        v-if="record2.type == 1"
        class="w-full video-detail rounded-t-xl items-center"
        :src="record2.urlImg"
        alt=""
        loading="lazy"
        data-src="@/assets/images/loading.gif"
        data-srcset="@/assets/images/loading.gif"
      />
      <video
        v-else
        class="w-full video-detail rounded-t-xl"
        preload="auto"
        poster="@/assets/images/loading.gif"
        controls
        autoplay
        muted
        loop
        playsinline
        id="video-detail"
      >
        <source src="" class="w-full" type="video/mp4" />
      </video>
    </div>
    <!-- <div
      class="flex items-center justify-center w-24 h-10 bg-agrogo-green-6 absolute rounded-br-2xl"
    >
      <a class="font-nexa font-regular text-sm text-white">Plus</a>
    </div>
    <div
      class="flex items-center justify-center w-11 h-10 bg-agrogo-yellow-1 absolute rounded-bl-3xl right-0"
    >
      <a class="icon-heart text-base text-white"></a>
    </div> -->
  </div>
  <!-- CARRUSEL IMAGENES -->
  <div class="w-auto h-auto bg-agrogo-green-2 md:bg-agrogo-white md:py-2">
    <div class="items-center px-2 py-2 overflow-x-scroll flex space-x-2">
      <div
        v-for="(item, index) in records"
        :key="index"
        :id="'card' + item.id"
        class="card-carousel w-12 h-12 lg:w-16 lg:h-16"
        @click.prevent="findRecord(item)"
      >
        <img
          class="w-full h-full"
          :src="item?.small_url ? item?.small_url : item?.urlImg"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    records: {
      type: Array,
      default: () => [],
    },
    record: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      record2: null,
    };
  },
  methods: {
    findRecord(item) {
      const me = this;
      const card = document.querySelectorAll(".card-carousel");
      const cardId = document.getElementById("card" + item.id);

      card.forEach((el) => {
        el.classList.forEach((x) => {
          if (x === "active-card-img") el.classList.remove("active-card-img");
        });
      });

      if (cardId) {
        cardId.classList.forEach((x) => {
          x === "active-card-img"
            ? cardId.classList.remove("active-card-img")
            : cardId.classList.add("active-card-img");
        });
      }

      me.record2 = item;
      if (me.record2.type == 2) {
        setTimeout(() => {
          me.loadVideo();
        }, 100);
      }
    },
    loadVideo() {
      const me = this;
      // Obtén la etiqueta de video por su ID
      const video = document.getElementById("video-detail");
      // Obtén el elemento <source> dentro de la etiqueta de video
      const source = video?.querySelector("source");

      // Asigna la fuente original nuevamente
      source.src = me.record2.urlVideo;
      video?.load();
      video?.play();
    },
  },
  created() {
    const me = this;
    me.record2 = me.record;
  },
  mounted() {
    const me = this;
    const cardId = document.getElementById("card" + me.record.id);
    cardId.classList.add("active-card-img");
    if (me.record2.type == 2) {
      me.loadVideo();
    }
  },
};
</script>

<style lang="css">
.card-carousel.active-card-img {
  border: 3px solid #f5ce35 !important;
}
.video-detail {
  height: 467px;
}

/*Media querys*/
/*** VERSION ESCRITORIO Y TABLETS PANTALLS GRANDE ***/
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .video-detail {
    height: 300px;
  }
}
/*** VERSION TABLETS ***/
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .video-detail {
    height: 300px;
  }
}
/*** VERSION CELULAR ***/
@media only screen and (max-width: 767px) {
  .video-detail {
    height: 300px;
  }
}
</style>
