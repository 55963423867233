<template>
  <section v-if="isBusy" class="containerGifLoading">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>
  <section v-else>
    <!-- CONTENEDOR 1 TERMINOS Y CONDICIONES -->
    <div
      class="flex flex-col bg-white items-center justify-center px-3 py-8 md:pt-10 md:pb-16"
    >
      <div
        class="flex items-center justify-center w-16 h-16 md:w-28 md:h-28 pb-1"
      >
        <img
          v-if="termsCondition.img_head_url"
          class="w-full h-full"
          :src="loadImg2(termsCondition.img_head_url)"
        />
      </div>
      <div
        class="font-nexa text-info-conditions text-base md:text-4xl text-agrogo-green-5"
        v-html="termsCondition.title"
      ></div>
    </div>
    <!-- PUNTOS REELEVANTES -->
    <div
      class="flex items-center justify-start px-8 h-10 bg-agrogo-green-3 md:justify-center md:h-10 md:mb-9 lg:h-12 lg:mb-11"
    >
      <div
        class="font-nexa font-normal text-agrogo-yellow-1 text-sm md:text-lg lg:text-2xl text-info-conditions"
        v-html="subTitleTearmsCondition"
      ></div>
    </div>
    <div
      class="flex flex-col pt-3 md:pt-0 h-auto md:py-6 justify-center items-center"
    >
      <div
        class="flex items-center pl-8 pr-7 md:px-0 style-info-step-7 style-info text-white text-sm md:text-base font-dinpro"
        v-html="listTearmsCondition"
      ></div>
    </div>
    <div
      class="h-3 bg-agrogo-green-3 md:h-7 md:mx-28 lg:mx-40 flex justify-end place-items-end"
    >
      <div class="div-yellow-tearms-conditions bg-agrogo-yellow-1"></div>
    </div>

    <div class="bg-white md:mx-28 lg:mx-40 md:pb-11 pb-5 pt-7 md:pt-11">
      <div class="container-terms-conditions py-8 scroll-color-step7 px-8 pt-0">
        <div class="h-full">
          <div>
            <div
              class="font-dinpro text-agrogo-black-1 text-sm text-left opacity-80 md:text-base text-info-conditions"
              v-html="textTermsCondition"
            ></div>
          </div>
        </div>
      </div>
    </div>

    <!-- VERIFICACION DE USUARIO -->
    <div
      class="flex items-center justify-start px-8 h-7 bg-agrogo-green-3 md:justify-center md:h-10 mb-8 md:mb-8 md:mt-20 lg:mt-28 lg:h-12"
    >
      <a
        class="font-nexa font-normal text-agrogo-yellow-1 text-sm md:text-lg lg:text-xl"
        ><span
          class="font-nexa font-black text-agrogo-yellow-1 text-sm md:text-lg lg:text-xl"
          >Verificación </span
        >de Usuario</a
      >
    </div>
    <div class="flex items-center justify-center pb-4" v-if="formData.nit">
      <div
        class="grid grid-cols-2 gap-2 md:grid-cols-3 px-10 md:px-6 lg:px-20 md:bg-agrogo-green-5 md:h-14 md:place-content-center w-full md:mx-10 lg:mx-40 max-w-container"
      >
        <div class="flex items-center justify-start">
          <a class="font-nexa text-white text-left text-sm lg:text-base">NIT</a>
        </div>
        <div class="flex items-center justify-end md:col-span-2">
          <input
            v-model="formData.nit"
            id="nit"
            name="nit"
            type="text"
            class="input-standard-disabled pl-4 md:h-8 lg:h-8 md:max-w-236"
            disabled
          />
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center">
      <div
        class="grid grid-cols-2 gap-2 md:grid-cols-3 px-10 md:px-6 lg:px-20 md:bg-agrogo-green-5 md:h-14 md:place-content-center w-full md:mx-10 lg:mx-40 max-w-container"
      >
        <div class="flex items-center justify-start">
          <a class="font-nexa text-white text-left text-sm lg:text-base">{{
            formData.nit
              ? "Cédula del representante legal"
              : "Cédula de ciudadanía"
          }}</a>
        </div>
        <div class="flex items-center justify-end md:col-span-2">
          <input
            v-model="v$.formData.documentNumber.$model"
            @blur="v$.formData.documentNumber.$touch()"
            @input="formatIdentification"
            :disabled="!formData.nit"
            id="documentNumber"
            name="documentNumber"
            type="text"
            :class="{
              'input-standard-transparent': formData.nit,
              'input-standard-disabled': !formData.nit,
            }"
            class="pl-4 md:h-8 md:max-w-236 cursor-not-allowed"
          />
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center px-4 pt-6 pb-4">
      <div
        class="text-agrogo-gray-1 text-left text-xs font-dinpro md:text-base"
      >
        Para poder aprobar tu proceso debe ser una foto nitida sin reflejos.
      </div>
    </div>
    <div class="flex space-x-2 justify-center pb-10 px-6">
      <div
        class="flex flex-col space-y-2 cursor-pointer"
        @click="$refs.fileInput.click()"
      >
        <img class="img-identification" :src="front" />
        <div class="flex flex-row items-center px-2 space-x-3">
          <a
            class="icon-upload text-agrogo-yellow-1 text-sm md:text-base md:ml-10"
          ></a>
          <a
            class="text-agrogo-gray-1 text-left text-xs font-dinpro md:text-base"
          >
            Cargar cara 1
            <input
              id="file"
              ref="fileInput"
              type="file"
              class="hidden"
              name="file0"
              accept=".jpg, .png, .jpeg, video/*"
              @change="fileChange"
              :disabled="isBusy2"
            />
          </a>
        </div>
      </div>
      <div
        class="flex flex-col space-y-2 cursor-pointer"
        @click="$refs.fileInput2.click()"
      >
        <img class="img-identification" :src="back" />
        <div class="flex flex-row items-center px-2 space-x-3">
          <a
            class="icon-upload text-agrogo-yellow-1 text-sm md:text-base md:ml-10"
          ></a>
          <a
            class="text-agrogo-gray-1 text-left text-xs font-dinpro md:text-base"
          >
            Cargar cara 2
            <input
              id="file"
              ref="fileInput2"
              type="file"
              class="hidden"
              name="file0"
              accept=".jpg, .png, .jpeg, video/*"
              @change="fileChange2"
              :disabled="isBusy2"
            />
          </a>
        </div>
      </div>
    </div>
    <div
      class="flex items-center justify-center space-x-2 md:px-20 pb-3 md:mb-2"
    >
      <input
        v-model="v$.formData.verify_tc.$model"
        @blur="v$.formData.verify_tc.$touch()"
        :true-value="true"
        :false-value="false"
        type="checkbox"
        class="custom-checkbox-test"
        id="verify_tc"
      />
      <label
        for="verify_tc"
        class="font-nexa text-agrogo-yellow-1 text-sm md:text-lg lg:text-xl text-left"
      >
        <span v-if="v$.formData.verify_tc.$invalid" class="text-agrogo-yellow-2"
          >*</span
        >
        Acepto todos los
        <span class="cursor-pointer scroll-down" @click.prevent="scroll_down"
          >términos y condiciones</span
        >
      </label>
    </div>
    <div class="flex items-center justify-center px-4 pb-2">
      <div
        class="text-agrogo-gray-1 text-left text-xs font-dinpro md:text-base"
      >
        Una vez terminado el proceso de publicación, este contrato será enviado
        a tu correo electrónico.
      </div>
    </div>

    <div class="flex items-center justify-center pb-8">
      <a class="font-dinpro text-white text-xs md:text-sm"
        >Datos para USO INTERNO DE
        <span class="font-nexa font-black md:text-sm">AGRO GO</span></a
      >
    </div>
    <!-- CONTENEDOR BOTONES DE NAVEGACION -->
    <div
      class="bg-agrogo-yellow-1 h-14 flex items-center justify-center space-x-3 md:space-x-4 lg:space-x-4"
    >
      <button
        @click.prevent="previus()"
        type="button"
        class="btn-primary"
        :disabled="isBusy2"
      >
        <span
          class="icon-desplegar flex items-center transform rotate-90 text-agrogo-yellow-2 text-xs mr-1 leading-none"
        ></span>
        Anterior
      </button>
      <button
        :disabled="v$.$invalid || isBusy2"
        @click.prevent="next()"
        type="button"
        class="btn-primary"
      >
        <div class="loader" v-if="isBusy2" />
        <div v-else class="flex">
          Siguiente
          <span
            class="icon-desplegar flex items-center transform -rotate-90 text-agrogo-yellow-2 text-xs ml-1 leading-none"
          ></span>
        </div>
      </button>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { generateHash } from "@/utils";
import { API_ROUTES } from "@/config";
import validationToken from "@/mixins/validationToken";
import beforeWindowMixin from "@/mixins/beforeWindowMixin";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import alertMessageMixin from "@/mixins/alertMessageMixin";

export default {
  mixins: [validationToken, beforeWindowMixin, alertMessageMixin],
  inject: ["provider"],
  data() {
    const me = this;
    return {
      v$: useVuelidate(),
      front: require("@/assets/images/document1.png"),
      back: require("@/assets/images/document2.png"),
      repository: me.provider.publishFarmRepository,
      profileRepository: me.provider.profileRepository,
      transationRepository: me.provider.transactionRepository,
      termsCondition: {},
      formData: {
        verify_tc: false,
        nit: null,
        documentNumber: null,
        back_cc: null,
        front_cc: null,
      },
      subTitleTearmsCondition: null,
      listTearmsCondition: null,
      textTermsCondition: null,
      isBusy: true,
      isBusy2: false,
      dirty: true,
      record: null,
    };
  },
  computed: {
    ...mapState("publishFarm", ["step", "farmSave", "dataSave"]),
    ...mapState("preLaunch", ["isPreLaunch"]),
  },
  validations() {
    return {
      formData: {
        verify_tc: {
          checked(val) {
            return val;
          },
        },
        documentNumber: {
          required,
        },
        back_cc: {
          required,
        },
        front_cc: {
          required,
        },
      },
    };
  },
  methods: {
    ...mapActions("publishFarm", ["findStep"]),
    async loadTermsConditions() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.getAllNames("TERMS_CONDITIONS", dataAditional);
        me.termsCondition = data;
        me.subTitleTearmsCondition = data.page_content[0].content;
        me.listTearmsCondition = data.page_content[1].content;
        me.textTermsCondition = data.page_content[2].content;
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      } finally {
        me.isBusy = false;
      }
    },
    previus() {
      const me = this;
      me.findStep(me.step.number2 - 1);
    },
    async next() {
      const me = this;
      if (!me.v$.$invalid) {
        // const next = me.isPreLaunch ? 8 : 7;
        if (me.isPreLaunch) {
          await me.savePayment();
          me.verifyIdentification();
        } else {
          // me.findStep(7);
          me.verifyIdentification();
        }
        // me.findStep(next);
        // me.savePayment();
        // me.verifyIdentification();
      }
    },
    fileChange(e) {
      const me = this;
      // Recoger el fichero
      const file = e.target.files[0];
      if (file) {
        const { type } = file;
        if (
          type != "image/jpeg" &&
          type != "image/jpg" &&
          type != "image/png"
        ) {
          me.$swal({
            icon: "error",
            title: "¡Formato no permitido!",
          });
        } else {
          me.loadImg(file, "1");
        }
      }
    },
    fileChange2(e) {
      const me = this;
      // Recoger el fichero
      const file = e.target.files[0];
      if (file) {
        const { type } = file;
        if (
          type != "image/jpeg" &&
          type != "image/jpg" &&
          type != "image/png"
        ) {
          me.$swal({
            icon: "error",
            title: "¡Formato no permitido!",
          });
        } else {
          me.loadImg(file, "2");
        }
      }
    },
    loadImg(file, type) {
      const me = this;

      // if (file["size"] <= 3145728) {
      const reader = new FileReader();
      reader.onload = () => {
        if (type == "1") {
          me.front = reader.result;
          me.formData.front_cc = file;
        } else {
          me.back = reader.result;
          me.formData.back_cc = file;
        }
      };
      reader.readAsDataURL(file);
      // } else {
      //   me.$swal({
      //     icon: "error",
      //     title: "Peso maxímo permitido es de 3MB",
      //   });
      // }
    },
    loadImg2(urlImg) {
      let url = `${API_ROUTES.image.get}${urlImg}`;
      return url;
    },
    async verifyIdentification() {
      const me = this;
      try {
        me.isBusy2 = true;
        const dataAditional = await generateHash();
        const formData = new FormData();
        formData.append(
          "documentNumber",
          me.formData.documentNumber.split(".").join("")
        );
        formData.append("back_cc", me.formData.back_cc);
        formData.append("front_cc", me.formData.front_cc);
        formData.append("apiKey", dataAditional.apiKey);
        formData.append("utcTimeStamp", dataAditional.utcTimeStamp);
        formData.append("signature", dataAditional.signature);

        await me.repository.verifyIdentification(formData);
        const next = me.isPreLaunch ? me.step.number2 + 2 : me.step.number2 + 1;
        me.findStep(next);
      } catch (error) {
        // console.log(error);
        const { response } = error.response.data.response;
        me.alertMenssage(response, 60000);
      } finally {
        me.isBusy2 = false;
      }
    },
    async getProfile() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();
        const {
          data: { response },
        } = await me.profileRepository.find(dataAditional);
        if (response && response.nit && response.razon_social) {
          me.formData.nit = response.nit;
        } else {
          me.formData.documentNumber = me.format(response.identy_document);
        }
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      } finally {
        me.isBusy = false;
      }
    },
    async savePayment() {
      const me = this;
      try {
        const idFarm = me.farmSave.id || me.dataSave.id;
        me.isBusy2 = true;
        const idItem = me.record?.id;
        const dataAditional = await generateHash();
        await me.transationRepository.save({
          ...dataAditional,
          property_id: idFarm,
          priority_id: idItem,
          price: me.record?.wompi_price,
          reference: me.record?.wompi_reference,
          hash: me.record?.wompi_integrity,
        });
        // const next = me.isPreLaunch ? 8 : 7;
        // me.findStep(next);
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
        me.isBusy2 = false;
      }
      // finally {
      //   me.isBusy2 = false;
      // }
    },
    async loadPriorities() {
      const me = this;
      try {
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.getAllPriorities(dataAditional);
        data.forEach((el) => {
          if (el.id == 6) me.record = el;
        });
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      }
    },
    scroll_down() {
      window.scrollBy(0, -1000);
    },
    format(number) {
      const formatter = new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      });
      return formatter.format(number);
    },
    formatIdentification() {
      const me = this;
      const formatter = new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      });
      me.formData.documentNumber = me.formData.documentNumber
        .split(".")
        .join("");
      if (isNaN(me.formData.documentNumber)) me.formData.documentNumber = 0;
      let attribute = formatter.format(me.formData.documentNumber);
      me.formData.documentNumber = attribute;
    },
  },
  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeUnmount() {
    this.dirty = false;
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  async mounted() {
    const me = this;
    window.scroll(0, 0);
    await me.loadTermsConditions();
    await me.getProfile();
    me.loadPriorities();
  },
};
</script>

<style lang="css" scoped>
.max_with {
  max-width: 50% !important;
}

.scroll-down {
  text-decoration-line: underline;
}
.img-identification {
  height: 12.5rem;
  width: 21.875rem;
}
@media only screen and (max-width: 767px) {
  .img-identification {
    height: 6.25rem;
    width: 15.625rem;
  }
}
.padding-top {
  padding-top: 10px;
}
@media (min-width: 1280px) {
  .max-w-container {
    max-width: 670px;
  }
}
</style>
