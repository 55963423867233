<template>
  <div
    class="flex flex-col space-y-6 md:min-h-64 md:bg-agrogo-green-5 lg:bg-agrogo-green-5 md:px-4 lg:px-4"
  >
    <div class="grid grid-cols-2">
      <div class="flex justify-start items-center">
        <span
          class="font-nexa text-white text-sm text-left md:text-base lg:text-lg"
          >{{ item.name }}</span
        >
      </div>

      <div class="flex justify-end">
        <v-select
          :options="subattributes"
          v-model="selected"
          :reduce="(option) => option.id.toString()"
          class="style-chooser2 w-full md:max-w-236"
          label="name"
          :disabled="userRol == 'Club AGRO GO'"
        >
          <template #no-options>Sin opciones</template>
        </v-select>
      </div>
    </div>
    <div v-if="isVisible" class="flex md:pl-2 lg:pl-2">
      <div class="flex items-center">
        <input-check-confirm
          :confirmationAuthorization="confirmationAuthorization"
          @checkConfirmation="checkConfirmation"
        />
      </div>
      <div class="flex items-end text-left">
        <label
          class="text-white text-xs lg:text-base font-dinpro font-normal block ml-3"
        >
          <span class="text-agrogo-yellow-2">*</span> Confirmo autorización
          oficial por parte del propietario para ofrecer la finca
        </label>
      </div>
    </div>
    <div
      v-if="isVisible"
      class="flex items-center pb-8 md:pl-2 lg:pl-2 justify-between"
    >
      <span
        class="font-dinpro font-normal text-white text-xs text-left md:text-base"
        >Datos para USO INTERNO DE
        <span class="font-nexa font-black">AGRO GO</span>
      </span>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import InputCheckConfirm from "./InputCheckConfirm";

export default {
  components: {
    InputCheckConfirm,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    confirmationAuthorization: {
      type: Boolean,
      default: false,
    },
    userRol: {
      type: String,
      default: null,
    },
  },
  data() {
    const me = this;
    return {
      selected: me.value == "" ? null : me.value,
      subattributes: [],
    };
  },
  watch: {
    selected() {
      const me = this;
      me.$emit("addSubattributesSelect", me.item.id, me.selected);
    },
    value(newValue) {
      const me = this;
      me.selected = newValue;
    },
  },
  computed: {
    isVisible() {
      const me = this;
      let visible = false;
      visible =
        me.selected == null || me.selected == "142" || me.selected == "5"
          ? false
          : true;

      me.$emit("checkVisible", visible);
      me.addCheckConfim(visible);
      return visible;
    },
  },
  methods: {
    ...mapActions("publishFarm", ["addCheckConfim"]),
    checkConfirmation(value) {
      const me = this;
      me.$emit("checkConfirmation", value);
    },
  },
  mounted() {
    const me = this;
    const subattributes = me.item.subattributes;
    subattributes.forEach((el, index) => {
      if (el.id == 142 && me.userRol && me.userRol !== "Club AGRO GO") {
        subattributes.splice(index, 1);
      }
    });
    me.subattributes = subattributes;
    const id = 142;
    if (me.userRol && me.userRol == "Club AGRO GO") {
      me.selected = id.toString();
      me.$emit("addSubattributesSelect", me.item.id, me.selected);
    }
  },
};
</script>

<style lang="css"></style>
