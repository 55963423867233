<template>
  <section
    class="md:min-h-64 md:bg-agrogo-green-5 lg:bg-agrogo-green-5 md:px-5 row-span-1"
  >
    <div class="grid grid-cols-2 place-content-center">
      <div class="flex items-center">
        <a class="font-nexa text-white text-sm text-left lg:text-base">{{
          item.name
        }}</a>
      </div>

      <div class="flex items-center justify-end">
        <v-select
          :options="item.subattributes"
          v-model="selected"
          :reduce="(option) => option.id.toString()"
          class="style-chooser2 w-full md:max-w-236"
          label="name"
        >
          <template #no-options>Sin opciones</template>
        </v-select>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    const me = this;
    return {
      selected: me.value == "" ? null : me.value,
    };
  },
  watch: {
    selected() {
      const me = this;
      me.$emit("addSubattributesSelect", me.item.id, me.selected);
    },
    value(newValue) {
      const me = this;
      me.selected = newValue;
    },
  },
};
</script>
