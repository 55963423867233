<template>
  <div class="card-img" style="relative">
    <a
      @click="$refs.fileInput.click()"
      class="flex items-center w-full h-full text-lg text-agrogo-black-1 px-2 py-2 cursor-pointer overflow-hidden"
    >
      <div class="flex flex-col">
        <div class="icon-imgadd" />
        <span class="font-nexa text-xs md:text-sm text-agrogo-black-1">
          Cargar Video y fotos
        </span>
        <input
          id="file"
          ref="fileInput"
          type="file"
          class="hidden"
          name="file0"
          accept=".jpg, .png, .jpeg, video/*"
          @change="fileChange"
          :disabled="isBusy"
          multiple
        />
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    fileChange(e) {
      this.$emit("fileChange", e);
    },
  },
};
</script>

<style></style>
