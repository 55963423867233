<template>
  <div
    v-if="item"
    class="flex min-h-56 lg:min-h-80 items-center justify-between pl-4 pr-8 space-x-4 md:pl-7 md:pr-3.5 lg:pl-10 lg:pr-7"
    :class="item.isStyle"
  >
    <a
      class="font-dinpro font-medium text-agrogo-black-1 text-sm text-left lg:text-lg"
      >{{ item.name }}
      <span class="font-regular text-xs md:text-sm">
        {{ item.unit ? `(${item.unit})` : "" }}</span
      >
    </a>
    <a
      v-for="(att, index) in item.subattributes"
      :key="index"
      class="font-dinpro text-agrogo-black-1 text-sm text-right lg:text-lg"
      >{{ contentValue(att) }}</a
    >
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  methods: {
    contentValue(value) {
      let val = null;
      value.value_attributes.forEach((el) => {
        val = `${value.name}.  ${el.value}`;
      });
      return val;
    },
  },
};
</script>

<style></style>
