<template>
  <div class="grid grid-cols-2 place-content-center">
    <div class="flex items-center justify-start">
      <a
        v-if="option"
        class="font-nexa text-white text-sm text-left md:text-base lg:text-lg"
        >{{ option.name }}</a
      >
    </div>

    <div class="flex items-center justify-end">
      <input
        v-model="attribute"
        :id="item.id_name"
        :name="item.id_name"
        type="number"
        class="input-standard-transparent md:w-full md:max-w-236"
        @keyup="addSubattributes"
      />
    </div>
  </div>
</template>

<script>
export default {
  emits: ["addSubattributes"],
  props: ["item", "value", "option"],
  data() {
    return {
      attribute: this.value,
    };
  },
  watch: {
    value(newValue) {
      const me = this;
      me.attribute = newValue;
      me.$emit("addSubattributes", me.option, me.attribute);
    },
  },
  methods: {
    addSubattributes() {
      const me = this;
      if (me.attribute == "") me.attribute = "";
      me.$emit("addSubattributes", me.option, me.attribute);
    },
  },
};
</script>
