<template>
  <Transition name="slide-fade">
    <div
      class="fixed z-50 inset-0 overflow-y-auto w-screen"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      v-if="isModalBilling"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div
          class="fixed inset-0 bg-black-trasparent transition-opacity"
          aria-hidden="true"
        ></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <Transition name="slide-fade">
          <div
            class="relative bg-transparent inline-block align-bottom shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
            v-if="isModal"
          >
            <div class="flex justify-end content-button-ModalLead">
              <button
                type="Button"
                class="btn-circle-ModalLead absolute"
                @click.prevent="() => $emit('closeModalBilling')"
              >
                <span class="icon-x"></span>
              </button>
            </div>
            <form-billing @next="next" />
          </div>
        </Transition>
      </div>
    </div>
  </Transition>
</template>

<script>
import FormBilling from "./FormBilling";
export default {
  emits: ["closeModalBilling", "nextWoompi"],
  components: {
    FormBilling,
  },
  props: {
    isModalBilling: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const me = this;
    return {
      isModal: me.isModalBilling,
      isBusy: false,
    };
  },
  watch: {
    isModalBilling(newData) {
      this.isModal = newData;
    },
  },
  computed: {},
  methods: {
    next(formData) {
      const me = this;
      me.$emit("nextWoompi", formData);
    },
  },
};
</script>

<style>
.bg-black-trasparent {
  background: rgba(0, 0, 0, 0.7);
}
</style>
