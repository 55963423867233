<template>
  <input
    :id="option.name"
    :name="option.name"
    type="text"
    class="input-standard-transparent w-12 ml-4 md:w-28"
    v-model="attribute"
    @keyup="validateNumber"
  />
</template>

<script>
export default {
  emits: ["addSubattributes", "calculateTotal"],
  props: {
    value: {
      type: String,
      default: null,
    },
    option: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      attribute: this.value,
    };
  },
  watch: {
    value(newValue) {
      const me = this;
      me.attribute = newValue;
      me.$emit("calculateTotal");
    },
  },
  methods: {
    format() {
      const me = this;
      let attribute = 0;
      const formatter = new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      });
      me.attribute = me.attribute.split(".").join("");
      if (isNaN(me.attribute)) me.attribute = 0;
      attribute = formatter.format(me.attribute);
      me.attribute = attribute;
    },
    validateNumber() {
      const me = this;
      me.attribute = parseInt(me.attribute.slice(0, 3));
      if (me.attribute > 100) {
        me.attribute = 100;
      }
      if (me.attribute < 0 || !me.attribute) {
        me.attribute = 0;
      }
      me.attribute = me.attribute.toString();
      me.$emit("addSubattributes", me.option, me.attribute);
    },
  },
};
</script>

<style></style>
