import { generateHash } from "@/utils";
import { mapActions, mapState } from "vuex";
import { create, all } from "mathjs";
import validationToken from "@/mixins/validationToken";

const config = {
  matrix: "Matrix",
};
const math = create(all, config);

export default {
  mixins: [validationToken],
  data() {
    return {
      attributes: [],
      attributeValidations: [],
      copyAttributeValidations: [],
      attributeCheckValidations: [],
      attributeSelectMultipleValidations: [],
      attributeSumation: [],
      list_steps: [],
      userRol: null,
    };
  },
  computed: {
    ...mapState("publishFarm", [
      "step",
      "steps",
      "formData2",
      "formData3",
      "formData4",
      "farmSave",
      "multimedia",
      "list_subcategories",
    ]),
  },
  methods: {
    ...mapActions("publishFarm", [
      "findStep",
      "addFormData2",
      "addFormData3",
      "addFormData4",
      "addDataSave",
      "addFarmSave",
    ]),
    async loadAttributes() {
      const me = this;
      me.attributes = [];
      me.attributeValidations = [];
      try {
        me.isBusy = true;
        me.list_steps = [];
        me.step.steps.map((el) => {
          me.list_steps.push(el.id);
        });

        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.stepAttributes({
          list_steps: me.list_steps,
          ...dataAditional,
        });

        data.forEach((el) => {
          const subattributes = [];
          if (el.subattributes.length > 0) {
            el.subattributes.map((y) => {
              if (y.name == "Latitud") {
                subattributes.push({
                  ...y,
                  value: null,
                });
              } else if (y.name == "Longitud") {
                subattributes.push({
                  ...y,
                  value: null,
                });
              } else if (el.type_attributes_input.id == 29) {
                subattributes.push({
                  ...y,
                  value: "0",
                });
              } else {
                subattributes.push({
                  ...y,
                  value: "",
                });
              }
            });
          }
          me.attributes.push({
            ...el,
            value: "",
            subattributes,
          });
        });
        data.forEach((el, index) => {
          if (el.required == "1") {
            if (
              el.type_attributes_input.id !== 23 &&
              el.type_attributes_input.id !== 17 &&
              el.type_attributes_input.id !== 26 &&
              el.type_attributes_input.id !== 32 &&
              el.type_attributes_input.id !== 34 &&
              el.type_attributes_input.id !== 33 &&
              el.subattributes.length > 0
            ) {
              el.subattributes.map((x) => {
                if (el.type_attributes_input.id == 29) {
                  me.attributeValidations.push({
                    attribute_id: x.attribute_id,
                    subattribute_id: x.id,
                    value: "0",
                  });
                } else {
                  me.attributeValidations.push({
                    attribute_id: x.attribute_id,
                    subattribute_id: x.id,
                    value: "",
                  });
                }
              });
              if (el.type_attributes_input.id == 29) {
                me.attributeSumation.push({
                  attribute_id: el.id,
                  isTotal: false,
                });
              }
            } else if (el.type_attributes_input.id == 17) {
              me.attributeCheckValidations.push({
                attribute_id: el.id,
                values: [],
              });
            } else if (el.type_attributes_input.id == 26) {
              me.attributeSelectMultipleValidations.push({
                attribute_id: el.id,
                values: [],
              });
            } else {
              me.attributeValidations.push({
                attribute_id: el.id,
                value: "",
                index: index,
              });
            }
          }
        });
        me.copyAttributeValidations = me.attributeValidations;
        //Calculos
        me.attributes.forEach((el) => {
          // Calculos
          if (el.composer_eq)
            me.calculateTotal(el.id, el.changeful, el.equation);
        });
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      } finally {
        if (!me.slug) me.isBusy = false;
      }
    },
    async loadAttributesEdit() {
      const me = this;
      try {
        me.isBusy = true;
        me.list_steps = [];
        me.step.steps.map((el) => {
          me.list_steps.push(el.id);
        });

        const dataAditional = await generateHash();
        const {
          data: { data, location },
        } = await me.repositoryEdit.stepAttributes({
          list_steps: me.list_steps,
          ...dataAditional,
          property_id: me.slug.split("-")[0],
        });
        let formData = {};
        formData.value_attributes = [];

        data.forEach((el) => {
          if (!Array.isArray(el)) {
            if (el?.subattributes.length > 0) {
              if (
                el?.type_attributes_input.id !== 23 &&
                el?.type_attributes_input.id !== 17 &&
                el?.type_attributes_input.id !== 26
              ) {
                el?.subattributes.forEach((x) => {
                  if (x.name == "Latitud") {
                    formData.value_attributes.push({
                      attribute_id: x.attribute_id,
                      subattribute_id: x.id,
                      value: Number(x.value),
                    });
                  } else if (x.name == "Longitud") {
                    formData.value_attributes.push({
                      attribute_id: x.attribute_id,
                      subattribute_id: x.id,
                      value: Number(x.value),
                    });
                  } else if (el?.type_attributes_input.id == 29) {
                    formData.value_attributes.push({
                      attribute_id: x.attribute_id,
                      value: x.value ? x.value : "0",
                      subattribute_id: x.id,
                    });
                  } else {
                    formData.value_attributes.push({
                      attribute_id: x.attribute_id,
                      value: x.value ? x.value : "",
                      subattribute_id: x.id,
                    });
                  }
                });
                if (el?.type_attributes_input.id == 29) {
                  me.attributeSumation.push({
                    attribute_id: el?.id,
                    isTotal: true,
                  });
                }
              } else if (el?.type_attributes_input.id == 17) {
                el?.subattributes.forEach((x) => {
                  let value = false;
                  if (x.value) {
                    value = true;
                  } else {
                    value = false;
                  }
                  formData.value_attributes.push({
                    attribute_id: x.attribute_id,
                    value: value,
                    subattribute_id: x.id,
                  });
                });
              } else if (el?.type_attributes_input.id == 26) {
                const values = [];
                el?.subattributes.forEach((x) => {
                  if (x.value) {
                    values.push(x);
                  }
                });

                formData.value_attributes.push({
                  attribute_id: el?.id,
                  value: values,
                  name: "selectMultiple",
                });
              } else if (el?.type_attributes_input.id == 23) {
                el?.subattributes.forEach((x) => {
                  if (x.value) {
                    formData.value_attributes.push({
                      attribute_id: x.attribute_id,
                      subattribute_id: x.id + "",
                      value: "0",
                    });
                  }
                });
              }
            } else {
              formData.value_attributes.push({
                attribute_id: el?.id,
                value: el?.value ? el?.value : "",
              });
            }
          }
        });
        if (me.step2) {
          const newData = me.formData2 ? me.formData2 : formData;
          me.addFormData2({
            ...newData,
            selectedDepart: me.formData2
              ? me.formData2.selectedDepart
              : location.department,
            municipality_id: me.formData2
              ? me.formData2.municipality_id
              : location.municipality,
          });
        } else if (me.step3) {
          const newData = me.formData3 ? me.formData3 : formData;
          me.addFormData3(newData);
        } else if (me.step4) {
          const newData = me.formData4 ? me.formData4 : formData;
          me.addFormData4(newData);
        }
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      } finally {
        me.isBusy = false;
      }
    },
    addValue(id, value) {
      const me = this;
      // // Todos los campos
      me.attributes.map((el) => {
        if (id == el.id) {
          el.value = value.split("$")[1]
            ? value.split("$")[1].split(".").join("")
            : value;
        }

        // Calculos
        if (el.composer_eq) me.calculateTotal(el.id, el.changeful, el.equation);
      });

      // Campos requeridos
      me.attributeValidations.map((el) => {
        if (id == el.attribute_id) {
          el.value = value.split("$")[1]
            ? value.split("$")[1].split(".").join("")
            : value;
        }
      });
      // me.fillFormData2();
    },
    addValueText(id, value) {
      const me = this;
      // // Todos los campos
      me.attributes.map((el) => {
        if (id == el.id) {
          el.value = value;
        }
      });
      // Campos requeridos
      me.attributeValidations.map((el) => {
        if (id == el.attribute_id) {
          el.value = value;
        }
      });
      me.fillFormData2();
    },
    addSubattributes(option, value) {
      const me = this;
      // Todos los campos
      me.attributes.map((el) => {
        if (el.subattributes && option.attribute_id == el.id) {
          el.subattributes.map((x) => {
            if (x.id == option.id) {
              x.value = value;
            }
          });
        }
      });
      // Campos requeridos
      me.attributeValidations.map((el) => {
        if (el.subattribute_id == option.id) {
          el.value = value;
        }
      });

      me.fillFormData2();
    },
    addSubattributes2(option, idSub, value) {
      const me = this;
      // Todos los campos
      me.attributes.map((el) => {
        if (el.subattributes && option.attribute_id == el.id) {
          el.subattributes.map((x) => {
            if (x.id == idSub) {
              x.value = value;
            }
          });
        }
      });
      // Campos requeridos
      me.attributeValidations.map((el) => {
        if (el.attribute_id == option.attribute_id) {
          el.value = value;
        }
        if (el.subattribute_id == idSub) {
          el.value = value;
        }
      });
      me.fillFormData2();
    },
    addValidationsAttributes(items, item) {
      const me = this;
      me.attributeValidations = [];
      if (items.length > 0) {
        items.forEach((el) => {
          el.subattributes.forEach((x, index) => {
            if (x.id == el.id) {
              me.attributeValidations.push({
                attribute_id: el.id,
                subattribute_id: x.id,
                value: x.value,
                index: index,
              });
            }
          });
        });
      } else {
        me.attributes.map((el) => {
          if (el.subattributes && item.id == el.id) {
            el.value = "";
            el.subattributes.map((x) => {
              x.value = "";
            });
          }
        });
        me.fillFormData2();
      }

      me.attributeValidations = [
        ...me.attributeValidations,
        ...me.copyAttributeValidations,
      ];

      if (items.length == 0) {
        // Campos requeridos
        me.attributeValidations.map((el) => {
          if (el.attribute_id == item.id) {
            el.value = "";
          }
        });
      }
    },
    addSubattributesSelect(id, value) {
      const me = this;
      // Todos los campos
      me.attributes.map((el) => {
        if (id == el.id) {
          el.value = value;
        }
      });

      // Campos requeridos
      me.attributeValidations.map((el) => {
        if (id == el.attribute_id) {
          el.value = value;
        }
      });

      me.fillFormData2();
    },
    addSubattributesCheck(option, value) {
      const me = this;
      me.attributes.map((el) => {
        if (el.id == option.attribute_id) {
          el.subattributes.map((x) => {
            if (x.id == option.id) {
              x.value = value;
            }
          });
        }
      });

      if (value) {
        me.attributeCheckValidations.map((z) => {
          if (option.attribute_id == z.attribute_id) {
            z.values.push({
              value: value,
              id: option.id,
            });
          }
        });
      } else {
        me.attributeCheckValidations.map((z) => {
          if (option.attribute_id == z.attribute_id) {
            if (z.values.length > 0) {
              z.values.map((y, index) => {
                if (y.id == option.id) {
                  z.values.splice(index, 1);
                }
              });
            }
          }
        });
      }
      me.fillFormData2();
    },
    addValueRadio(id, value) {
      const me = this;
      const permuta = me.attributes.find(
        (el) => id == el.id && el.id_name == "PERMUTA"
      );

      if (permuta) {
        const percentage = me.attributes.find(
          (el) => el.id_name == "PORC_PERMUTA"
        );
        const index = me.attributes.findIndex(
          (el) => el.id_name == "PORC_PERMUTA"
        );

        if (value == "1") {
          me.isPercentage = true;
          me.attributeValidations.push({
            attribute_id: percentage.id,
            value: percentage.value !== "" ? percentage.value : "",
            index: index,
          });
        } else {
          const attribute = me.attributeValidations.find(
            (el) => el.attribute_id == percentage.id
          );
          if (attribute) {
            const index2 = me.attributeValidations.findIndex(
              (el) => el.attribute_id == percentage.id
            );
            me.attributeValidations.splice(index2, 1);
          }
          me.isPercentage = false;
        }
        me.addValue(id, value);
      } else {
        me.addValue(id, value);
      }
    },
    addValuePercentage(i, value) {
      const me = this;
      // Todos los campos
      me.attributes.map((el, index) => {
        if (i == index) {
          el.value = value;
        }
      });

      // Campos requeridos
      me.attributeValidations.map((el) => {
        if (el.attribute_id == 9) {
          el.value = value;
        }
      });

      me.fillFormData2();
    },
    addSubattributesSelectMultiple(id, values) {
      const me = this;
      me.attributes.map((el) => {
        if (el.id == id) {
          el.value = values;
          el.subattributes.map((x) => {
            x.value = "";
            values.map((z) => {
              if (parseInt(z) == x.id) {
                x.value = parseInt(z);
              }
            });
          });
        }
      });
      me.attributeSelectMultipleValidations.forEach((el) => {
        if (el.attribute_id == id) {
          el.values = values;
        }
      });
      me.fillFormData2();
    },
    setTotal(id, isTotal) {
      const me = this;
      me.attributeSumation.forEach((el) => {
        if (el.attribute_id == id) {
          el.isTotal = isTotal;
        }
      });
    },
    fillFormData2() {
      const me = this;
      me.formData.value_attributes = [];
      me.attributes.map((el) => {
        if (
          el.type_attributes_input.id !== 23 &&
          el.type_attributes_input.id !== 17 &&
          el.type_attributes_input.id !== 26 &&
          el.type_attributes_input.id !== 32 &&
          el.type_attributes_input.id !== 33 &&
          el.type_attributes_input.id !== 34 &&
          el.subattributes.length > 0
        ) {
          el.subattributes.map((x) => {
            me.formData.value_attributes.push({
              attribute_id: x.attribute_id,
              value: x.value,
              subattribute_id: x.id,
            });
          });
        } else if (el.type_attributes_input.id == 23) {
          me.formData.value_attributes.push({
            attribute_id: el.id,
            subattribute_id: el.value,
            value: "0",
          });
        } else if (el.type_attributes_input.id == 17) {
          el.subattributes.map((x) => {
            if (x.value) {
              me.formData.value_attributes.push({
                attribute_id: x.attribute_id,
                value: x.value,
                subattribute_id: x.id,
              });
            }
          });
        } else if (el.type_attributes_input.id == 34) {
          el.subattributes.map((x) => {
            if (x.value) {
              me.formData.value_attributes.push({
                attribute_id: x.attribute_id,
                value: x.value,
                subattribute_id: x.id,
              });
              const record = me.formData.value_attributes.find(
                (z) => z.subattribute_id == x.subattribute_id
              );
              if (!record) {
                me.formData.value_attributes.push({
                  attribute_id: x.attribute_id,
                  value: "1",
                  subattribute_id: x.subattribute_id,
                });
              }
            }
          });
        } else if (el.type_attributes_input.id == 33) {
          el.subattributes.map((x) => {
            if (x.value) {
              me.formData.value_attributes.push({
                attribute_id: x.attribute_id,
                value: x.value,
                subattribute_id: x.id,
              });
              const record = me.formData.value_attributes.find(
                (z) => z.subattribute_id == x.subattribute_id
              );
              if (!record) {
                me.formData.value_attributes.push({
                  attribute_id: x.attribute_id,
                  value: "1",
                  subattribute_id: x.subattribute_id,
                });
              }
            }
          });
        } else if (el.type_attributes_input.id == 26) {
          me.formData.value_attributes.push({
            attribute_id: el.id,
            value: el.value,
            name: "selectMultiple",
          });
          el.subattributes.map((x) => {
            if (x.value !== "") {
              me.formData.value_attributes.push({
                attribute_id: x.attribute_id,
                value: x.value,
                subattribute_id: x.id,
              });
            }
          });
        } else if (el.id !== 17 && el.id !== 28) {
          me.formData.value_attributes.push({
            attribute_id: el.id,
            value: el.value,
          });
        }
      });

      // Buscar permuta
      const permuta = me.formData.value_attributes.find(
        (el) => el.attribute_id == 8
      );
      if (permuta) {
        me.formData.value_attributes.forEach((el) => {
          if (el.attribute_id == 9 && permuta.value !== "1") {
            el.value = "";
          }
        });
      }
      if (me.step2) {
        me.addFormData2(me.formData);
      } else if (me.step3) {
        me.addFormData3(me.formData);
      } else if (me.step4) {
        me.addFormData4(me.formData);
      }
    },
    calculateTotal(id, variables, equation) {
      const me = this;
      let scope = {};
      let total = null;

      const variables2 = variables.split(",");

      variables2.map((el) => {
        me.attributes.map((x) => {
          if (x.id_name == el) {
            if (x.value !== "") {
              let value = x.value;

              if (x.value && x.value.split(".")[1]) {
                value = x.value.split(".").join("");
              }

              if (x.value && x.value.split(",")[1]) {
                value = x.value.split(".").join("").split(",").join(".");
              }

              scope[x.id_name] = parseFloat(value);
            } else {
              scope[x.id_name] = 0;
            }
          }
        });
      });

      total = math.evaluate(equation, Object.assign({}, scope));

      if (isNaN(total)) total = "";
      if (!isFinite(total)) total = 0;
      let attribute = "";
      let value = total.toString();
      if (value) {
        let regex = /(\d*.\d{0,2})/;
        attribute = value.match(regex)[0];
      }

      // Todos los campos
      me.attributes.map((el) => {
        if (id == el.id) {
          el.value = attribute;
        }
        // Campos requeridos
        me.attributeValidations.map((el) => {
          if (id == el.attribute_id) {
            el.value = attribute;
          }
        });
      });
    },
    async save() {
      const me = this;
      me.isBusy2 = true;
      try {
        const value_attributes = [];

        //formData2
        if (me.formData2) {
          me.formData2.value_attributes.forEach((el) => {
            if (el.value !== "" && el.value !== undefined) {
              value_attributes.push(el);
            }
          });
        }

        //formData3
        if (me.formData3) {
          me.formData3.value_attributes.forEach((el) => {
            if (el.value !== "" && el.value !== undefined) {
              if (
                el.attribute_id == 1 ||
                el.attribute_id == 7 ||
                el.attribute_id == 6
              ) {
                el.value = el.value.trim();
              }
              value_attributes.push(el);
            }
          });
        }

        //formData4
        if (me.formData4) {
          me.formData4.value_attributes.forEach((el) => {
            if (el.value !== "" && el.value !== undefined && !el.name) {
              value_attributes.push(el);
            }
          });
        }

        const option = me.farmSave?.id ? "update" : "save";
        const id = me.farmSave?.id ? me.farmSave.id : null;

        let nextStep = null;
        if (me.step.number2 != 4 && me.nextStep < 7) {
          const record = me.steps.find((el) => el.number2 == me.nextStep);
          nextStep = record?.id;
        } else if (me.step.number2 == 4) {
          nextStep = 5;
        } else {
          nextStep = 6;
        }
        const dataAditional = await generateHash();
        const { data } = await me.repository[option]({
          id: id,
          subcategories_ids: me.list_subcategories,
          municipality_id: me.formData2 ? me.formData2.municipality_id.id : "",
          value_attributes,
          ...dataAditional,
          last_step: nextStep,
        });

        me.addFarmSave({
          isFarmSave: true,
          id: data.property.id,
        });
        me.findStep(me.nextStep);
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      } finally {
        me.isBusy2 = false;
      }
    },
    async getRol() {
      let me = this;
      const dataAditional = await generateHash();
      try {
        let { data: data } = await me.provider.authRepository.getRol(
          dataAditional
        );
        me.userRol = data.user.role_user[0];
      } catch (error) {
        // console.log(error);
      }
    },
  },
  async mounted() {
    window.scroll(0, 0);
  },
};
