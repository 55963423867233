<template>
  <div class="md:min-h-64 md:bg-agrogo-green-5 md:px-5 row-span-1">
    <div
      class="flex flex-col md:pt-0 lg:pt-0 h-auto w-auto space-y-3 md:px-0 lg:px-0"
    >
      <div class="flex items-center justify-between">
        <a
          v-if="item"
          class="font-nexa text-white text-sm text-left lg:text-base"
          >{{ item.name }} {{ item.unit ? `(${item.unit})` : "" }}
        </a>
      </div>
      <div class="grid grid-cols-2">
        <div
          class="flex items-center text-left space-x-2 mb-5"
          v-for="(option, index) in item.subattributes"
          :key="index"
        >
          <input-check
            :item="item"
            :option="option"
            :value="option.value == '' ? false : option.value"
            @addCheck="addCheck"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputCheck from "./InputCheck";

export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  components: {
    InputCheck,
  },
  data() {
    return {};
  },
  methods: {
    addCheck(option, value) {
      this.$emit("addSubattributesCheck", option, value);
    },
  },
};
</script>
