<template>
  <div
    class="grid grid-cols-2 place-content-center md:bg-agrogo-green-5 md:px-5 md:min-h-64 mb-4 items-center"
  >
    <a
      class="font-nexa font-normal text-white text-xs md:text-sm lg:text-lg text-left"
      v-if="item"
      >{{ item.name }}
      <span class="">{{ item.unit ? `(${item.unit})` : "" }}</span></a
    >
    <div class="flex justify-end">
      <input
        :id="item.id_name"
        :name="item.id_name"
        type="text"
        class="input-standard-transparent w-3/4 md:w-full md:max-w-236"
        v-model="attribute"
        @keyup="format"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      attribute: this.value,
    };
  },
  watch: {
    value(newValue) {
      const me = this;
      me.attribute = newValue;
      me.$emit("addValue", me.item.id, me.attribute);
    },
  },
  methods: {
    format() {
      const me = this;
      let attribute = 0;
      const formatter = new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      });
      const area10 = me.attribute.split(",");
      if (area10.length > 2) {
        me.attribute = `${area10[0]}${
          area10[1] !== "" ? `,${area10[1]}` : ","
        }`;
      }
      let area = me.attribute.replace(/[^\d,.]/g, "");
      let area2 = me.attribute.replace(/[^\d,.]/g, "");
      let isValidate = true;

      if (me.attribute.split(".")[1]) {
        area = me.attribute
          .split(".")
          .join("")
          .replace(/[^\d,.]/g, "");
        attribute = formatter.format(area);
      }

      if (me.attribute.split(",")[1]) {
        if (me.attribute.split(".")[1]) {
          const area3 = me.attribute
            .split(",")[0]
            .split(".")
            .join("")
            .replace(/[^\d,.]/g, "");
          area2 = formatter.format(area3);
        } else {
          const area3 = me.attribute.split(",")[0].replace(/[^\d,.]/g, "");
          area2 = formatter.format(area3);
        }
        isValidate = false;
        let t = area.toString();
        let regex = /(\d*.\d{0,1})/;
        const attribute2 = t.match(regex)[0];
        attribute = `${area2.split(",")[0]},${attribute2.split(",")[1]}`;
      } else {
        area = me.attribute
          .split(".")
          .join("")
          .replace(/[^\d,.]/g, "");
        attribute = formatter.format(area);
      }

      if (isNaN(attribute.split(".").join("")) && isValidate) attribute = area2;
      me.attribute = attribute;
      me.$emit("addValue", me.item.id, me.attribute);
    },
  },
};
</script>
