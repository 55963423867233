<template>
  <div
    class="flex items-center justify-between md:min-h-64 md:bg-agrogo-green-5 lg:bg-agrogo-green-5 md:px-5"
  >
    <div class="flex w-1/2">
      <div
        v-if="item"
        class="flex font-nexa bold text-white text-sm text-left md:md:max-w-198 md:text-base lg:text-lg"
      >
        {{ item.name }}
      </div>
    </div>
    <div
      class="flex justify-center items-center mr-3 md:mr-0"
      v-for="(option, index) in options"
      :key="index"
    >
      <label
        :for="item.name + index"
        class="text-white text-xs md:text-base lg:text-lg font-dinpro font-normal block mr-2 hover:text-agrogo-yellow-1"
      >
        {{ option.text }}
      </label>
      <input
        v-model="attribute"
        :value="option.value"
        :id="item.name + index"
        :name="item.name + index"
        type="radio"
        class="custom-radio-test"
        @change="addValue"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      attribute: this.value,
      options: [
        { text: "Si", value: "1" },
        { text: "No", value: "0" },
      ],
    };
  },
  watch: {
    value(newValue) {
      const me = this;
      me.attribute = newValue;
    },
    attribute() {
      const me = this;
      me.$emit("addValueRadio", me.item.id, me.attribute);
    },
  },
};
</script>

<style></style>
