<template>
  <section v-if="isBusy" class="containerGifLoading">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>

  <section v-else>
    <div class="hidden md:flex h-9"></div>
    <div>
      <div
        class="flex bg-white md:mx-8 lg:mx-16 md:rounded-t-50 md:space-x-4 lg:space-x-8 2xl:space-x-12 2xl:space-x-14"
      >
        <!-- CONTENEDOR IMAGEN PRINCIPAL Y CARRUSEL -->
        <div class="main w-full md:w-1/2 md:pl-8 lg:pl-24">
          <div
            class="md:pt-10 pt-2 grid grid-cols-1 md:overflow-hidden md:gap-x-3 lg:gap-x-5 bg-agrogo-green-2 md:bg-white md:rounded-t-2xl"
          >
            <div>
              <div class="md:grid md:grid-cols-1 md:bg-white">
                <slider-img :records="records" :record="record" />
                <!-- CONTENEDOR DESCRIPCION DESKTOP -->
                <div
                  class="hidden md:flex items-center justify-start ml-10 my-7"
                >
                  <a class="font-nexa lg:text-2xl text-agrogo-green-2"
                    ><span class="font-black">Descripción </span>de la Finca
                  </a>
                </div>
              </div>
              <!-- TEXTO DEBAJO DETALLES DE FICA MOBILE -->
              <div class="md:hidden bg-agrogo-green-2 pb-8 relative">
                <div
                  class="flex flex-col items-start justify-start pt-1 pb-4 px-4"
                >
                  <a class="font-dinpro text-xs text-agrogo-yellow-1"
                    >ID#
                    <span v-if="data" class="text-sm text-agrogo-gray-1">{{
                      data.id
                    }}</span></a
                  >
                  <a
                    v-if="data"
                    class="font-nexa font-normal text-white text-xl text-left"
                  >
                    {{ data.area.value }}
                    {{ data.area.unit ? data.area.unit : "" }}
                    {{ data.municipalities.name }}, {{ data.departments.name }}
                  </a>
                </div>
                <div class="flex w-full">
                  <div
                    v-if="data"
                    class="flex flex-col w-1/2 items-start justify-center space-y-2 pl-5"
                  >
                    <div class="flex w-full">
                      <span
                        class="text-agrogo-yellow-1 icon-flower text-sm text-center w-1/6 pr-1"
                      ></span>
                      <div class="flex items-center justify-start">
                        <span
                          class="font-dinpro font-medium text-base text-agrogo-yellow-1 w-full"
                          >Tipo de Finca:</span
                        >
                      </div>
                    </div>
                    <div
                      v-for="(item, index) in data.subcategory"
                      :key="index"
                      class="flex w-full"
                    >
                      <span
                        class="text-base text-agrogo-gray-1 text-center w-1/5 pr-1"
                        :class="item.icon"
                      ></span>
                      <div class="flex items-center justify-start">
                        <span
                          class="font-dinpro font-normal text-base text-agrogo-gray-1"
                          >{{ item.name }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col w-1/2 items-end justify-start pr-5">
                    <a
                      class="font-dinpro text-base font-medium text-agrogo-yellow-1"
                      >Precio Total</a
                    >
                    <a
                      v-if="data"
                      class="font-dinpro font-bold text-lg text-agrogo-gray-1"
                      ><span
                        class="text-base icon-signo-pesos leading-none text-agrogo-yellow-1"
                      ></span>
                      {{ format(data.price.value) }}</a
                    >
                    <a
                      v-if="data"
                      class="font-dinpro text-2xs text-agrogo-gray-1 md:text-base"
                      >({{ data.price.unit }})</a
                    >
                  </div>
                </div>
              </div>
              <div
                class="flex items-center justify-center h-28 bg-white md:hidden"
              >
                <span class="text-agrogo-green-2 font-nexa font-normal text-lg">
                  <span class="font-black">Descripción</span> de la Finca</span
                >
              </div>
            </div>
          </div>

          <!-- CONTAINER CARDS  -->
          <farm-description :data="data" />
        </div>
        <div class="hidden md:flex side w-1/2 md:rounded-tr-50 pt-12">
          <!-- TEXTO DETALLES DE FINCA DESKTOP-->
          <div class="hidden md:flex flex-col w-full">
            <div class="flex flex-col items-start justify-center pb-8">
              <a
                class="font-dinpro font-normal text-base text-agrogo-green-4 pb-2"
                >ID#
                <span v-if="data" class="text-agrogo-black-1">{{
                  data.id
                }}</span></a
              >
              <a
                v-if="data"
                class="font-nexa font-normal text-agrogo-black-1 text-2xl lg:text-3xl text-left"
              >
                {{ data.area.value }}
                {{ data.area.unit ? data.area.unit : "" }}
                {{ data.municipalities.name }}, {{ data.departments.name }}
              </a>
            </div>
            <div class="grid grid-cols-2 pb-4">
              <div
                v-if="data"
                class="grid grid-cols-1 place-items-start place-content-center gap-y-2"
              >
                <a
                  class="font-dinpro lg:text-base font-medium text-agrogo-green-4 pb-2"
                  ><span class="icon-flower text-base"></span> Tipo de Finca:
                </a>
                <div
                  v-for="(item, index) in data.subcategory"
                  :key="index"
                  class="flex w-full"
                >
                  <span
                    class="text-2xl text-agrogo-black-1 text-center w-1/3 lg:w-1/4"
                    :class="item.icon"
                  ></span>
                  <div class="flex items-center justify-start">
                    <span
                      class="font-dinpro font-normal text-base text-agrogo-black-1"
                      >{{ item.name }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-1 place-content-start">
                <a
                  class="font-dinpro font-medium text-base text-agrogo-green-4 text-right pb-1.5"
                  >Precio Actual</a
                >
                <a
                  v-if="data"
                  class="font-dinpro font-bold text-2xl xl:text-3xl text-agrogo-black-1 text-right"
                  ><span
                    class="icon-signo-pesos text-xl xl:text-2xl leading-none text-agrogo-green-4"
                  ></span>
                  {{ format(data.price.value) }}</a
                >
                <a
                  v-if="data"
                  class="font-dinpro font-normal text-2xs text-agrogo-black-1 text-right"
                  >({{ data.price.unit }})</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- CONTENEDOR BOTONES NAVEGACION -->
    <div
      class="bg-agrogo-yellow-1 h-14 flex items-center justify-center space-x-3 md:space-x-4 lg:space-x-4"
    >
      <button @click="previus()" type="button" class="btn-primary">
        <span
          class="icon-desplegar flex items-center transform rotate-90 text-agrogo-yellow-2 text-xs mr-1 leading-none"
        ></span>
        Anterior
      </button>
      <button @click="next()" type="button" class="btn-primary">
        Siguiente
        <span
          class="icon-desplegar flex items-center transform -rotate-90 text-agrogo-yellow-2 text-xs ml-1 leading-none"
        ></span>
      </button>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { API_ROUTES } from "@/config";
import { generateHash } from "@/utils";
import validationToken from "@/mixins/validationToken";
import beforeWindowMixin from "@/mixins/beforeWindowMixin";
// Components
import SliderImg from "./components/SliderImg";
import FarmDescription from "./components/FarmDescription";

export default {
  inject: ["provider"],
  mixins: [validationToken, beforeWindowMixin],
  components: {
    SliderImg,
    FarmDescription,
  },
  data() {
    return {
      repository: this.provider.publishFarmRepository,
      records: [],
      record: null,
      data: null,
      isBusy: true,
      dirty: true,
    };
  },
  computed: {
    ...mapState("publishFarm", ["step", "farmSave"]),
  },
  methods: {
    ...mapActions("publishFarm", ["findStep"]),
    previus() {
      const me = this;
      me.findStep(me.step.number2 - 1);
    },
    next() {
      const me = this;
      me.findStep(me.step.number2 + 1);
    },
    async loadFarm() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.find(me.farmSave.id, dataAditional);
        me.data = data;
        me.fillData();
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      } finally {
        me.isBusy = false;
      }
    },
    fillData() {
      const me = this;
      me.data.multimedia_property.forEach((el) => {
        if (el.type == 2) {
          me.records.push({
            ...el,
            urlImg: require("@/assets/images/previewvideo.png"),
            urlVideo: `${API_ROUTES.image.get}${el.media.default_url}`,
          });
        } else {
          let width = screen.width;
          if (width > "1024") {
            me.records.push({
              ...el,
              urlImg: `${API_ROUTES.image.get}${el.media.large_url}`,
              small_url: `${API_ROUTES.image.get}${el.media.small_url}`,
            });
          } else if (width <= "720") {
            me.records.push({
              ...el,
              urlImg: `${API_ROUTES.image.get}${el.media.medium_url}`,
              small_url: `${API_ROUTES.image.get}${el.media.small_url}`,
            });
          } else {
            me.records.push({
              ...el,
              urlImg: `${API_ROUTES.image.get}${el.media.large_url}`,
              small_url: `${API_ROUTES.image.get}${el.media.small_url}`,
            });
          }
        }
      });

      me.records.sort((a, b) => {
        if (a.type < b.type) {
          return 1;
        }
        if (a.type > b.type) {
          return -1;
        }
        return 0;
      });

      me.data.attribute_groups.forEach((el) => {
        if (el.id == 1) {
          el.attribute.forEach((x) => {
            x.order = 3;
            x.type = 1;
          });

          el.attribute.push({
            ...me.data.departments,
            id_name: "Departamento",
            order: 1,
            type: 2,
            value_attributes: [{ value: me.data.departments.name }],
            type_attributes_input: {
              id: null,
              name: "ubication",
            },
          });

          el.attribute.push({
            ...me.data.municipalities,
            id_name: "Municipio",
            order: 2,
            type: 2,
            value_attributes: [{ value: me.data.municipalities.name }],
            type_attributes_input: {
              id: null,
              name: "ubication",
            },
          });

          el.attribute.sort((a, b) => {
            if (a.order > b.order) {
              return 1;
            }
            if (a.order < b.order) {
              return -1;
            }
            return 0;
          });
        }
      });
      me.data.attribute_groups.forEach((el) => {
        let counter = 0;
        el.attribute.forEach((x) => {
          counter++;
          let isActive = null;

          if (counter % 2 == 0) {
            isActive = "bg-agrogo-gray-2";
          } else {
            isActive = "";
          }

          x.isStyle = isActive;
        });
      });
      me.data.attribute_groups.forEach((el) => {
        if (el.attribute.length > 0) {
          el.attribute.forEach((x, index) => {
            if (x.id == 8) {
              x.value_attributes.forEach((y) => {
                if (y.value == "0") el.attribute.splice(index, 1);
              });
            }
          });
        }
      });
      me.record = me.records[0];
    },
    format(price) {
      const formatterPeso = new Intl.NumberFormat("es-CO");

      return formatterPeso.format(price);
    },
  },
  created() {
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeUnmount() {
    this.dirty = false;
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  mounted() {
    const me = this;
    window.scroll(0, 0);
    me.loadFarm();
  },
};
</script>

<style lang="css" scoped>
@media (min-width: 768px) {
  .md\:bg-white {
    background-color: #ffffff !important;
  }
}
</style>
