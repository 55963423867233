<template>
  <div
    class="grid grid-cols-2 place-content-center md:min-h-64 md:bg-agrogo-green-5 lg:bg-agrogo-green-5 md:px-5 row-span-1"
  >
    <div class="flex flex-col items-start justify-center" v-if="item">
      <span
        class="font-nexa font-bold text-white text-sm text-left md:text-base lg:text-lg capitalize"
      >
        {{ item.name }}
      </span>
      <span
        class="font-dinpro font-normal text-agrogo-gray-1 text-xs text-left"
        >{{ item.unit ? `(${item.unit})` : "" }}</span
      >
    </div>
    <div class="flex justify-end items-center">
      <input
        :id="item.id_name"
        :name="item.id_name"
        type="text"
        class="input-standard-transparent md:max-w-236 cursor-not-allowed input-price"
        v-model="attribute"
        placeholder="$ 0"
        disabled
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      attribute: this.value,
    };
  },
  watch: {
    value(newValue) {
      const me = this;
      me.attribute = newValue;
      me.format();
    },
  },
  methods: {
    format() {
      const me = this;
      let price = 0;

      if (me.attribute.split("$")[1]) {
        me.attribute = me.attribute.split("$")[1].split(".").join("");

        if (isNaN(me.attribute)) me.attribute = 0;
      } else {
        if (isNaN(me.attribute)) me.attribute = 0;
      }

      const formatterPeso = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      });

      price = formatterPeso.format(me.attribute);
      me.attribute = price;
    },
  },
};
</script>

<style></style>
