<template>
  <div
    class="grid grid-cols-2 place-content-center md:min-h-64 md:bg-agrogo-green-5 lg:bg-agrogo-green-5 md:px-5"
  >
    <div class="flex items-center">
      <div
        v-if="item"
        class="flex font-nexa text-white text-sm text-left md:md:max-w-198 md:text-lg"
      >
        {{ item.name }}
      </div>
    </div>
    <div
      class="flex w-full items-center pr-2 justify-end space-x-5 md:pr-2 md:space-x-6"
    >
      <button
        class="flex font-nexa text-agrogo-yellow-1 text-sm md:md:max-w-198 md:text-3xl lg:text-3xl btn-barter"
        @click.prevent="minus"
        :disabled="validationPercentageBarterMinus || !isPercentage"
      >
        -
      </button>
      <input
        id="porcentaje-permuta"
        name="porcentaje-permuta"
        type="text"
        class="input-standard-disabled max-w-48 md:max-w-110"
        v-model="attribute"
        disabled
        @blur="validationPercentageBarterInput"
        placeholder=""
        @keyup="format"
      />
      <button
        class="flex font-nexa text-agrogo-yellow-1 text-sm md:md:max-w-198 md:text-3xl lg:text-3xl btn-barter"
        @click.prevent="plus"
        :disabled="validationPercentageBarterPlus || !isPercentage"
      >
        +
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    isPercentage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const me = this;
    return {
      attribute: me.value,
    };
  },
  watch: {
    value(newValue) {
      this.attribute = newValue;
    },
    attribute() {
      const me = this;
      me.$emit("addValuePercentage", me.index, me.attribute.toString());
    },
  },
  computed: {
    validationPercentageBarterMinus() {
      const me = this;
      let isDisabled = false;

      if (me.attribute <= 5) {
        isDisabled = true;
      }

      return isDisabled;
    },
    validationPercentageBarterPlus() {
      const me = this;
      let isDisabled = false;

      if (me.attribute >= 100) {
        isDisabled = true;
      }

      return isDisabled;
    },
  },
  methods: {
    minus() {
      const me = this;
      me.attribute = Number(me.attribute) - 5;
    },
    plus() {
      const me = this;
      me.attribute = Number(me.attribute) + 5;
    },
    validationPercentageBarterInput() {
      const me = this;

      if (isNaN(me.attribute) || me.attribute > 100 || me.attribute < 0)
        me.attribute = 5;
    },
    format() {
      const me = this;
      if (isNaN(me.attribute)) me.attribute = 5;
    },
  },
  mounted() {
    const me = this;
    me.attribute = me.value ? me.value : "5";
  },
};
</script>

<style></style>
