<template>
  <div
    class="flex min-h-56 lg:min-h-80 items-center justify-between pl-4 pr-8 py-2 space-x-4 md:pl-7 md:pr-3.5 lg:pl-10 lg:pr-7"
    :class="item.isStyle"
  >
    <div class="w-2/4 flex justify-start">
      <a
        class="font-dinpro font-medium text-agrogo-black-1 text-sm text-left lg:text-lg"
        >{{ item.name }}</a
      >
    </div>
    <div class="w-2/4">
      <ul>
        <li
          class="font-dinpro text-agrogo-black-1 text-sm text-left flex justify-between lg:text-lg"
          v-for="(att, index) in item.subattributes"
          :key="index"
        >
          <template v-if="validateValue(att.value_attributes)">
            <div>
              {{ att.name }}
            </div>
            <div v-for="val in att.value_attributes" :key="val.id">
              {{ val.value }}%
            </div>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  methods: {
    validateValue(value_attributes) {
      let isValidate = false;

      value_attributes.forEach((el) => {
        el.value !== "0" ? (isValidate = true) : (isValidate = false);
      });
      return isValidate;
    },
  },
};
</script>

<style></style>
