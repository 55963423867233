<template>
  <section
    class="md:min-h-64 md:bg-agrogo-green-5 lg:bg-agrogo-green-5 md:px-5 row-span-1"
  >
    <div class="grid grid-cols-4 place-content-center">
      <div class="flex items-center">
        <a class="font-nexa text-white text-sm text-left lg:text-base">{{
          item.name
        }}</a>
      </div>
      <div class="col-span-3 flex items-center justify-end">
        <v-select
          :options="item.subattributes"
          v-model="selected"
          :reduce="(option) => option.id.toString()"
          class="style-chooser3 w-full text-right"
          label="name"
          :close-on-select="false"
          multiple
        >
          <template #no-options>Sin opciones</template>
        </v-select>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["item", "values"],
  data() {
    return {
      selected: this.values ? this.values : [],
    };
  },
  watch: {
    selected() {
      const me = this;
      me.$emit("addSubattributesSelectMultiple", me.item.id, me.selected);
    },
    values(newValue) {
      const me = this;
      me.selected = newValue;
    },
  },
};
</script>
