<template>
  <section v-if="isBusy2" class="containerGifLoading">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>
  <section v-else class="">
    <!-- CONTENEDOR DESTACA TU FINCA -->
    <div class="md:px-28 lg:px-28 md:pt-8 lg:pt-8 md:space-x-6 lg:space-x-6">
      <div class="grid grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-5 py-4 xl:py-0">
        <div v-for="(item, index) in priorities.slice(0, 2)" :key="index">
          <card-primary
            :item="item"
            :idItem="idItem"
            :isBusy="isBusy"
            @selected="saveData"
            @openModal="openModal"
          />
        </div>
      </div>
    </div>
    <div
      class="md:px-28 lg:px-28 md:pt-4 lg:pt-4 md:pb-4 lg:pb-4 md:space-x-5 lg:space-x-5 pb-8"
    >
      <div class="grid grid-cols-1 xl:grid-cols-3 gap-x-4 gap-y-5">
        <div v-for="(item, index) in priorities.slice(2, 5)" :key="index">
          <card-secondary
            :item="item"
            :idItem="idItem"
            :isBusy="isBusy"
            @selected="saveData"
          />
        </div>
      </div>
    </div>
    <!-- CONTENEDOR BOTONES NAVEGACION -->
    <div
      class="bg-agrogo-yellow-1 h-14 flex items-center justify-center space-x-3 md:space-x-4 lg:space-x-4"
    >
      <button
        @click="previus()"
        :disabled="isBusy || dataSave"
        type="button"
        class="btn-primary"
      >
        <span
          class="icon-desplegar flex items-center transform rotate-90 text-agrogo-yellow-2 text-xs mr-1 leading-none"
        ></span>
        Anterior
      </button>
    </div>
    <form action="https://checkout.wompi.co/p/" method="GET" id="form-wompi">
      <!-- OBLIGATORIOS -->
      <input type="hidden" name="public-key" v-model="formData.public_key" />
      <input type="hidden" name="currency" v-model="formData.currency" />
      <input
        type="hidden"
        name="amount-in-cents"
        v-model="formData.amount_in_cents"
      />
      <input type="hidden" name="reference" v-model="formData.reference" />
      <!-- OPCIONALES -->
      <input
        type="hidden"
        name="signature:integrity"
        v-model="formData.signature_integrity"
      />
      <input
        type="hidden"
        name="redirect-url"
        v-model="formData.redirect_url"
      />
      <input
        type="hidden"
        name="shipping-address:address-line-1"
        :value="formData.address"
      />
      <input
        type="hidden"
        name="shipping-address:phone-number"
        :value="formData.phone_number"
      />
      <input type="hidden" name="shipping-address:country" value="CO" />
      <input
        type="hidden"
        name="shipping-address:city"
        :value="formData.municipality_id"
      />
      <input
        type="hidden"
        name="shipping-address:region"
        :value="formData.department_id"
      />
      <!-- <button type="submit" class="button" /> -->
    </form>
    <modal-video
      :urlVideo="urlVideo"
      :isModal="isModal"
      @closeModal="isModal = false"
    />
    <modal-billing
      :isModalBilling="isModalBilling"
      @closeModalBilling="isModalBilling = false"
      @nextWoompi="nextWoompi"
    />
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { generateHash } from "@/utils";
import validationToken from "@/mixins/validationToken";
import CardPrimary from "./CardPrimary";
import CardSecondary from "./CardSecondary";
import ModalVideo from "./components/ModalVideo";
import ModalBilling from "./components/ModalBilling";

export default {
  inject: ["provider"],
  mixins: [validationToken],
  components: {
    CardPrimary,
    CardSecondary,
    ModalVideo,
    ModalBilling,
  },
  data() {
    const me = this;
    const wompiKey =
      process.env.VUE_APP_DEBUG == "true"
        ? process.env.VUE_APP_WOMPI_KEY_TEST
        : process.env.VUE_APP_WOMPI_KEY;
    return {
      repository: me.provider.publishFarmRepository,
      profileRepository: me.provider.profileRepository,
      transationRepository: me.provider.transactionRepository,
      priorities: [],
      isBusy: false,
      isBusy2: true,
      isModal: false,
      isModalBilling: false,
      idItem: null,
      host: null,
      urlVideo: null,
      record: null,
      formData: {
        public_key: wompiKey,
        currency: "COP",
        amount_in_cents: null,
        reference: null,
        signature_integrity: null,
        redirect_url: null,
        municipality_id: null,
        department_id: null,
        country_id: null,
        address: null,
        phone_number: null,
      },
    };
  },
  computed: {
    ...mapState("publishFarm", ["step", "farmSave", "dataSave"]),
  },
  methods: {
    ...mapActions("publishFarm", ["findStep", "updateIsStep"]),
    previus() {
      const me = this;
      me.findStep(me.step.number2 - 1);
    },
    async loadPriorities() {
      const me = this;
      try {
        me.isBusy2 = true;
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.getAllPriorities(dataAditional);
        me.priorities = data;
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      } finally {
        me.isBusy2 = false;
      }
    },
    async saveData(item) {
      const me = this;
      if (item.wompi_price !== 0) {
        me.record = item;
        me.isModalBilling = true;
      } else {
        try {
          const idFarm = me.farmSave.id || me.dataSave.id;
          me.isBusy = true;
          me.idItem = item.id;

          const dataAditional = await generateHash();
          await me.transationRepository.save({
            ...dataAditional,
            property_id: idFarm,
            priority_id: me.idItem,
            price: item.wompi_price,
            reference: item.wompi_reference,
            hash: item.wompi_integrity,
          });
          me.findStep(me.step.number2 + 1);
        } catch (error) {
          const code = error.response.status;
          if (code == 401) {
            me.validationExpiredToken();
          }
        } finally {
          me.isBusy = false;
        }
      }
    },
    openModal(url) {
      const me = this;
      me.urlVideo = url;
      me.isModal = true;
    },
    nextWoompi(formData) {
      const me = this;
      me.isModalBilling = false;
      me.formData.municipality_id = formData?.municipality?.id;
      me.formData.department_id = formData?.municipality?.state_id;
      me.formData.country_id = formData?.municipality?.countryId;
      me.formData.address = formData?.address;

      me.$swal
        .fire({
          iconHtml:
            '<svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M40.0005 6.66797L48.7555 13.0546L59.5938 13.0346L62.9222 23.348L71.7022 29.7013L68.3338 40.0013L71.7022 50.3013L62.9222 56.6546L59.5938 66.968L48.7555 66.948L40.0005 73.3346L31.2455 66.948L20.4072 66.968L17.0788 56.6546L8.29883 50.3013L11.6672 40.0013L8.29883 29.7013L17.0788 23.348L20.4072 13.0346L31.2455 13.0546L40.0005 6.66797Z" stroke="#F5CE3E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path d="M28.334 40.0013L36.6673 48.3346L53.334 31.668" stroke="#F5CE3E" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          customClass: {
            icon: "no-border-swal-alert",
          },
          text: "Para realizar el pago, sera redirigido a la pasarela de pago de Wompi.",
          showCancelButton: true,
          confirmButtonText: "OK",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              const idFarm = me.farmSave.id || me.dataSave.id;
              me.isBusy = true;
              me.formData.amount_in_cents = me.record?.wompi_price;
              me.formData.reference = me.record?.wompi_reference;
              me.formData.signature_integrity = me.record?.wompi_integrity;
              me.formData.redirect_url = `${me.host}/verificando-pago?proId=${idFarm}`;
              me.idItem = me.record?.id;
              const dataAditional = await generateHash();
              await me.transationRepository.save({
                ...dataAditional,
                property_id: idFarm,
                priority_id: me.idItem,
                price: me.record?.wompi_price / 100,
                reference: me.record?.wompi_reference,
                hash: me.record?.wompi_integrity,
              });
              document.getElementById("form-wompi").submit();
            } catch (error) {
              const code = error.response.status;
              if (code == 401) {
                me.validationExpiredToken();
              }
            } finally {
              me.isBusy = false;
            }
          }
        });
    },
    async getProfile() {
      const me = this;
      try {
        const dataAditional = await generateHash();
        const { data: data } = await me.profileRepository.find(dataAditional);
        me.formData.phone_number = data?.response?.telephone;
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      }
    },
  },

  mounted() {
    const me = this;
    window.scroll(0, 0);
    me.loadPriorities();
    me.host = window.location.origin;
    me.updateIsStep(false);
    me.getProfile();
  },
};
</script>
