<template>
  <div
    class="grid grid-cols-2 place-content-center md:min-h-64 md:bg-agrogo-green-5 lg:bg-agrogo-green-5 md:px-5"
  >
    <div class="flex justify-start items-center">
      <a
        class="font-nexa font-normal text-white text-xs text-left lg:text-base"
        v-if="item"
        >{{ item.name }}
        <span
          class="font-nexa text-agrogo-gray-1 text-sm text-left lg:text-lg"
          >{{ item.unit ? `(${item.unit})` : "" }}</span
        ></a
      >
    </div>

    <div class="flex justify-end items-center">
      <input
        :id="item.id_name"
        :name="item.id_name"
        type="text"
        class="input-standard-transparent md:max-w-236"
        v-model="attribute"
        @keyup="format"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      attribute: this.value,
    };
  },
  watch: {
    value(newValue) {
      const me = this;
      me.attribute = newValue;
      me.$emit("addValueText", me.item.id, me.attribute);
    },
  },
  methods: {
    format() {
      const me = this;
      me.$emit("addValueText", me.item.id, me.attribute);
    },
  },
};
</script>

<style></style>
